










import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Alert extends Vue {
    show: boolean = false
    text: string = ''
    state?: number = 0
    commitAction?: () => void

    alert(): void {
        this.show = true
        if (this.state === 1) {
            setTimeout(this.commit, 3000)
        }
    }
    close(): void {
        this.show = false
    }
    commit(): void {
        this.show = false
        this.commitAction?.()
    }
}
