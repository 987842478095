import Vue from 'vue'
import Alert from './alert.vue'
/* eslint-disable */

const getContainers = () => {
    let Containers = document.getElementById('Containers')
    if (!Containers) {
        Containers = document.createElement('div')
        Containers.setAttribute('id', 'Containers')
        document.body.appendChild(Containers)
    } else {
        Containers.innerHTML = ''
    }
    return Containers
}
const alert = (options: AlertOpt) => {
    const Containers = getContainers()
    const VueComponent = Vue.extend(Alert)
    const vm = new VueComponent().$mount()
    Object.assign(vm, options)
    Containers!.appendChild(vm.$el);
    (vm as any).alert()
}
export {
    alert
}
interface AlertOpt {
    text: string | any;
    state?: number;
    commitAction?(): void;
}