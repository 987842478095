import { Component, Vue, Watch } from 'vue-property-decorator'
import { post, get, login, loginByCode } from '@/http'
import md5 from 'js-md5'
import { alert } from '@/components/pop'

@Component
export default class Methods extends Vue {
    loginShow: boolean = true
    coverShow: number = 0
	sendStr: string = '获取验证码'
    timer?: any = null
    times: number = 0
	canSend: boolean = false  //手机号校验通过
	canSend1: boolean = false  //手机号校验通过
	canSend2: boolean = false  //手机号校验通过
    wait: boolean = false     //已发送验证码，等待
	phone: string = ''
	account: string = ''
	name: string = ''
	password: string = ''
	code: string = ''
	email: string = ''
	format: boolean = false
	loginAccount: string | any = ''
	loginPhone: string | any = ''
	loginEmail: string | any = ''
	loginNickName: string | any = ''
	messageShow: boolean = false
	contactName: string = ''
	contactCompany: string = ''
	contactPhone: string = ''
	contactEmail: string = ''
	contactDesc: string = ''
	searchKey1: string = ''
	settledShow: boolean = false
	taskShow: boolean = false
	typeText: string = ''
	taskPhone: string = ''
	taskDemand: string = ''
	taskDesc: string = ''
	typeShow: boolean = false
	demandList: any = []
	demandList1: any = []
	storeList: any = []
	storeList1: any = []
	haveStore: string | any = '0'
	typeList: any = [
		{
			name: '产品/UI设计'
		},
		{
			name: '软件开发'
		},
		{
			name: '微信开发'
		},
		{
			name: 'H5开发'
		},
		{
			name: 'APP开发'
		},
		{
			name: '网站建设'
		}
	]
	searchShow: boolean = false
	searchKey: string = ''
	searchList: any = []

    created(): void {
		document.title = 'App开发、小程序开发、网站系统开发、各行业软件开发公司'
		this.loginShow = sessionStorage.getItem('state') === '1' ? false : true
		this.loginAccount = sessionStorage.getItem('account')
		this.loginPhone = sessionStorage.getItem('phone')
		this.loginEmail = sessionStorage.getItem('email')
		this.loginNickName = sessionStorage.getItem('nickName')
		this.haveStore = sessionStorage.getItem('createStore') 
		this.taskPhone = this.loginPhone
		this.typeText = this.typeList[0].name
	}
	search(): void {
		if (!(this.searchKey.replace(/[, ]/g, '').length > 0)) {
			alert({text: '请输入搜索关键词', state: 1})
			return
		}
		this.searchShow = false
		this.searchList = ''
		post('software/search', {
            keyword: this.searchKey
        }).then((res: any) => {
			this.searchShow = true
			this.searchList = res.data
        })
	}
	dealHtml(text: string | any): string {
        const result = text.replace(this.searchKey, (v: any) => {
            return "<span style='font-weight: bold; font-size: .18rem;'>" + v + "</span>"
        })
        return result
    }
    back(): void {
        this.$router.back()
    }
	gotoAgreement(): void {
		this.canScroll()
		this.$router.push({
            name: 'Agreement'
        })
	}
	gotoOtherPage(name: string, id: number): void {
		this.$router.push({
            name,
			params: {
				id: id + ''
			}
        })
	}
	gotoOtherPage1(name: string): void {
		this.canScroll()
		this.$router.push({
            name
        })
	}
	gotoDetail(id: number): void {
		this.$router.push({
            name: 'Details',
			params: {
				id: id + ''
			}
        })
	}
    dealNum(): void {
        this.phone = this.phone.replace(/[^0-9]/g, '')
    }
    dealNum1(): void {
        this.code = this.code.replace(/[^0-9]/g, '')
    }
	dealNum2(): void {
        this.contactPhone = this.contactPhone.replace(/[^0-9]/g, '')
    }
	dealNum4(): void {
        this.taskPhone = this.taskPhone.replace(/[^0-9]/g, '')
    }
    showCover(num: number): void {
		this.coverShow = num
		this.phone = ''
		this.code = ''
		this.canSend = false
		this.wait = false
		this.messageShow = false
		this.password = ''
		this.sendStr = '获取验证码'
		this.name = ''
		this.email = ''
		this.account = ''
		clearTimeout(this.timer)
		this.noScroll()
	}
	close(): void {
		this.coverShow = 0
		this.canScroll()
	}
	closeMessage(): void {
		if (this.messageShow) {
			this.messageShow = false
		}
	}
	showMessage(): void {
		this.messageShow = !this.messageShow
	}
	openSettled(): void {
		if (sessionStorage.getItem('userId') == null) {
            alert({text: '请登录后再进行店铺入驻', state: 1})
            return
        }
		if (sessionStorage.getItem('createStore') === '1') {
            alert({text: '一个帐号只能创建一个店铺', state: 1})
            return
        }
		this.noScroll()
		this.settledShow = true
	}
	closeSettled(): void {
		this.canScroll()
		this.settledShow = false
	}
	openTask(): void {
		if (sessionStorage.getItem('userId') == null) {
            alert({text: '请登录后再发布需求', state: 1})
            return
        }
		this.taskDemand = ''
		this.taskDesc = ''
		this.typeText = this.typeList[0].name
		this.noScroll()
		this.taskShow = true
	}
	closeTask(): void {
		this.canScroll()
		this.taskShow = false
		this.typeShow = false
	}
	openType(): void {
		this.typeShow = !this.typeShow
	}
	closeType(): void {
		this.typeShow = false
	}
	checkType(name: string): void {
		this.typeText = name
		this.typeShow = false
	}
	openHire(): void {
		if (sessionStorage.getItem('userId') == null) {
            alert({text: '请登录后再进行雇佣申请', state: 1})
            return
        }
		alert({text: '我们已收到您的雇佣申请，正在为您对接商家', state: 1})
	}
    getCode(num: number): void {
		if (this.canSend && !this.wait) {
            post('user/sendValidateSms', {
				phoneNumber: this.phone,
				type: num
			}).then(() => {
                this.wait = true
                this.times = 60
                this.sendStr = '60s'
                this.timer = setTimeout(this.timeOut, 1000)
            })
        }
	}
	timeOut(): void {
        if (this.times > 0) {
            this.times--
            this.sendStr = this.times + 's'
            this.timer = setTimeout(this.timeOut, 1000)
        } else {
            this.wait = false
            this.sendStr = '获取验证码'
        }
    }
	register(): void {
		if (!(this.name.replace(/[, ]/g, '').length > 0)) {
			alert({text: '请输入名称', state: 1})
			return
		}
		if (!this.canSend) {
			alert({text: '请输入手机号', state: 1})
			return
		}
		if (this.code.length !== 4) {
			alert({text: '请输入短信验证码', state: 1})
			return
		}
		const myreg = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/
		if (!(this.email.replace(/[, ]/g, '').length > 0 && myreg.test(this.email))) {
			alert({text: '请输入有效的邮箱', state: 1})
			return
		}
		if (!this.format) {
            alert({text: '请输入6-20位含数字和英文的密码', state: 1})
            return
        }
		post('user/register', {
			nickname: this.name,
			phoneNumber: this.phone,
			validateCode: this.code,
			email: this.email,
			password: md5(this.password)
		}).then(() => {
			this.coverShow = 0
			this.canScroll()
			alert({text: '您已注册成功！', state: 1})
		})
	}
	loginByCode(): void {
		if (!this.canSend) {
			alert({text: '请输入手机号', state: 1})
			return
		}
		if (this.code.length !== 4) {
			alert({text: '请输入短信验证码', state: 1})
			return
		}
		loginByCode(this.phone, this.code).then((res: any) => {
			this.coverShow = 0
			this.canScroll()
			this.loginShow = false
			this.loginAccount = this.phone
			this.loginPhone = res.data.phoneNumber
			this.loginEmail = res.data.email
			this.loginNickName = res.data.nickName
			this.haveStore = res.data.createStore ? '1' : '0'
            sessionStorage.setItem('userId', res.data.userId)
			sessionStorage.setItem('state', '1')
			sessionStorage.setItem('account', this.phone)
			sessionStorage.setItem('phone', res.data.phoneNumber)
			sessionStorage.setItem('email', res.data.email)
			sessionStorage.setItem('nickName', res.data.nickName)
			sessionStorage.setItem('createStore', this.haveStore)
        })
	}
	login(): void {
		if (!(this.account.replace(/[, ]/g, '').length > 0)) {
			alert({text: '请输入手机号或邮箱', state: 1})
			return
		}
		if (!this.format) {
            alert({text: '请输入6-20位含数字和英文的密码', state: 1})
            return
        }
		login(this.account, this.password).then((res: any) => {
			this.coverShow = 0
			this.canScroll()
			this.loginShow = false
			this.loginAccount = this.account
			this.loginPhone = res.data.phoneNumber
			this.loginEmail = res.data.email
			this.loginNickName = res.data.nickName
			this.haveStore = res.data.createStore ? '1' : '0'
            sessionStorage.setItem('userId', res.data.userId)
			sessionStorage.setItem('state', '1')
			sessionStorage.setItem('account', this.account)
			sessionStorage.setItem('phone', res.data.phoneNumber)
			sessionStorage.setItem('email', res.data.email)
			sessionStorage.setItem('nickName', res.data.nickName)
			sessionStorage.setItem('createStore', this.haveStore)
        })
	}
	changePwd(): void {
		if (!this.canSend) {
			alert({text: '请输入手机号', state: 1})
			return
		}
		if (this.code.length !== 4) {
			alert({text: '请输入短信验证码', state: 1})
			return
		}
		if (!this.format) {
            alert({text: '请输入6-20位含数字和英文的密码', state: 1})
            return
        }
		post('user/password/update', {
			phoneNumber: this.phone,
			validateCode: this.code,
			password: md5(this.password)
		}).then(() => {
			this.coverShow = 0
			this.loginOut()
			this.canScroll()
		})
	}
	loginOut(): void {
		this.messageShow = false
		this.loginShow = true
        sessionStorage.removeItem('userId')
		sessionStorage.removeItem('state')
		sessionStorage.removeItem('account')
		sessionStorage.removeItem('phone')
		sessionStorage.removeItem('email')
		sessionStorage.removeItem('nickName')
		sessionStorage.removeItem('createStore')
	}
	submit(): void {
		if (!(this.contactName.replace(/[, ]/g, '').length > 0)) {
			alert({text: '请输入您的姓名', state: 1})
			return
		}
		if (!(this.contactPhone.replace(/[, ]/g, '').length > 0)) {
			alert({text: '请输入您的电话', state: 1})
			return
		}
		if (!(this.contactDesc.replace(/[, ]/g, '').length > 0)) {
			alert({text: '输入您的咨询内容', state: 1})
			return
		}
		post('user/consult', {
			name: this.contactName,
			company: this.contactCompany,
			email: this.contactEmail,
			phone: this.contactPhone,
			content: this.contactDesc
		}).then(() => {
			alert({text: '您的咨询内容已提交', state: 1})
			this.contactName = ''
			this.contactCompany = ''
			this.contactEmail = ''
			this.contactPhone = ''
			this.contactDesc = ''
		})
	}
	submitTask(): void {
		if (!(this.taskDemand.replace(/[, ]/g, '').length > 0)) {
			alert({text: '请输入您的项目方向', state: 1})
			return
		}
		if (!(this.taskDesc.replace(/[, ]/g, '').length > 0)) {
			alert({text: '请输入您的需求', state: 1})
			return
		}
		if (!this.canSend1) {
			alert({text: '请输入手机号', state: 1})
			return
		}
		post('demand/save', {
			type: this.typeText,
			demand: this.taskDemand,
			desc: this.taskDesc,
			phone: this.taskPhone,
			userId: sessionStorage.getItem('userId')
		}).then(() => {
			this.taskShow = false
			this.taskDemand = ''
			this.taskDesc = ''
			this.typeText = this.typeList[0].name
			this.canScroll()
			alert({text: '我们已收到您的需求，马上帮您寻找商家', state: 1})
			if (this.$route.name === 'Index' || this.$route.name === 'Demand') {
				this.getDemand()
			}
		})
	}
	getDemand(): void {
		get('demand/list', {
        }).then((res: any) => {
            this.demandList = res.data
			this.demandList1 = this.demandList.slice(0, 10)
        })
	}
	getStore(): void {
		get('store/list', {
			userId: sessionStorage.getItem('userId')
        }).then((res: any) => {
            this.storeList = res.data
			this.storeList1 = this.storeList.slice(0, 10)
        })
	}
    noScroll(): void {
        const { body } = document
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        body.style.position = 'fixed'
        body.style.width = '100%'
        body.style.top = `-${scrollTop}px`
    }
    // 允许页滚动
    canScroll(): void {
        const { body } = document
        const { top } = body.style
        body.style.position = ''
        body.style.width = ''
        body.style.top = ''
        document.body.scrollTop = -parseInt(top, 10)
        document.documentElement.scrollTop = -parseInt(top, 10)
    }
    @Watch('phone')
    changeCanSend(n: string): void {
        const PhoneReg = /^[1][3-9]\d{9}$|^(00852)\d{8}$|^(00886)\d{9}$|^(00853)\d{8}$/
        this.canSend = PhoneReg.test(n) ? true : false
    }
	@Watch('password')
    passwordFormat(): void {
        const PasswordReg = /^(?=.*[A-Za-z])(?=.*\d)[^]{6,20}$/
        this.format = PasswordReg.test(this.password) ? true : false
    }
	@Watch('taskPhone')
    changeCanSend1(n: string): void {
        const PhoneReg = /^[1][3-9]\d{9}$|^(00852)\d{8}$|^(00886)\d{9}$|^(00853)\d{8}$/
        this.canSend1 = PhoneReg.test(n) ? true : false
    }
	@Watch('contactPhone')
    changeCanSend2(n: string): void {
        const PhoneReg = /^[1][3-9]\d{9}$|^(00852)\d{8}$|^(00886)\d{9}$|^(00853)\d{8}$/
        this.canSend2 = PhoneReg.test(n) ? true : false
    }
	@Watch('searchKey')
    searchKeyChange(): void {
        if (this.searchKey.replace(/[, ]/g, '').length <= 0) {
			this.searchShow = false
		}
    }
}