import axios from 'axios'
import md5 from 'js-md5'
import { alert } from './components/pop'

// axios.defaults.withCredentials = true
axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'http://10.17.191.58:8888/api/' : 'http://www.7725.com/web/api/'

export function post(url: string, params: any): any { // eslint-disable-line
    return new Promise((resolve, reject) => {
        axios.post(url, params, {
        }).then((res: any) => {
            if (res.data.status) {
                resolve(res.data)
            } else {
                alert({text: res.data.message, state: 1})
            }
        }).catch((err: any) => {
            reject(err)
        })
    })
}
export function get(url: string, params: any): any {// eslint-disable-line
    const queryData = {
        params: params,
    }
    return new Promise((resolve, reject) => {
        axios.get(url, queryData)
        .then((res: any) => {
            if (res.data.status) {
                resolve(res.data)
            } else {
                alert({text: res.data.message, state: 1})
            }
        }).catch((err: any) => {
            reject(err)
        })
    })
}
export function postFile(url: string, params: any): any { // eslint-disable-line
    return new Promise((resolve, reject) => {
        axios.post(url, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res: any) => {
            if (res.data.status) {
                resolve(res.data)
            } else {
                alert({text: res.data.message, state: 1})
            }
        }).catch((err: any) => {
            reject(err)
        })
    })
}
export function login(accountNumber: string, password: string): any {
    return new Promise((resolve, reject) => {
        post('user/login/by_password', {
            loginAccount: accountNumber,
            password: md5(password)
        }).then((res: any) => {
            resolve(res)
        }).catch((err: any) => {
            reject(err)
        })
    })
}
export function loginByCode(phoneNumber: string, code: string): any {
    return new Promise((resolve, reject) => {
        post('user/login/by_phone', {
            phoneNumber: phoneNumber,
            validateCode: code
        }).then((res: any) => {
            resolve(res)
        }).catch((err: any) => {
            reject(err)
        })
    })
}